import { template as template_426cee1fa8654be4beb343e169885230 } from "@ember/template-compiler";
const FKControlMenuContainer = template_426cee1fa8654be4beb343e169885230(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
