import { template as template_343ccad523af4993950d113e0f48bdca } from "@ember/template-compiler";
const SidebarSectionMessage = template_343ccad523af4993950d113e0f48bdca(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
