import { template as template_e038934077544d1192e962815315dd92 } from "@ember/template-compiler";
const FKText = template_e038934077544d1192e962815315dd92(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
